.modalBackground {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 10000000;
}

.modalLoginContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 500px;
  max-height: 500px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  pointer-events: auto;
  outline: 0;
  padding: 13px;
  z-index: 1000;
}
.modalBody {
  width: 100%;
  margin: 0 auto;
}
.inputWrapper {
  width: 65%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-evenly;
}
.loginInputContainer {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.react-tel-input {
  font-family: 'Roboto';
  font-size: 15px;
  position: relative;
  width: 100%;
}
.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 46px;
  top: -8px;
  background: #fff;
  padding: 0 2px;
  white-space: nowrap;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
}
.title {
  h3 {
    padding: 13px 0px;
    margin: 0;
    margin-bottom: 6.5px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0;
    color: #000;
    opacity: 1;
  }
}
.otp_text {
  font-size: 16px;
  @media screen and (max-width: 520px) {
    font-size: 13px;
  }
}

.bottomLoginContent {
  padding-top: 26px;
  margin: 0;
  p {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    color: #535353;
    margin-top: 0;
    margin-bottom: 13px;
    line-height: 1.2;
  }
  span {
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    color: #1a295b;
  }
}
@media screen and (max-width: 520px) {
  .modalLoginContainer {
    max-width: 400px;
  }
}
.close {
  position: absolute;
  right: 0;
  top: 20px;
}
.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
  button {
    background-color: #fff;
    border: 0px;
  }
  img {
    vertical-align: middle;
  }
}
