.footer {
  width: 100%;
  display: block;
  background: #1a295b;
  color: #fff;
  padding: 40px 0 0;
  padding-bottom: 1.5rem !important;
  padding-top: 3rem !important;

  .footer-container {
    margin-left: auto;
    margin-right: auto;

    .ro {
      display: flex;
      flex-wrap: wrap;
      .logo {
        color: #ffb657;
        width: 90px;
        background-size: contain;
        height: 30px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 25px;
        border: none !important;
        cursor: pointer;
      }
    }
    .footer-menu {
      margin-bottom: 1rem;
    }
    h5 {
      margin-bottom: 8px;
      font-family: Roboto !important;
      font-weight: 500 !important;
      font-size: 1.333rem !important;
      margin-top: 1.5rem !important;
    }
    p {
      font-family: Roboto !important;
      font-size: 1.1rem !important;
      font-weight: 200 !important;
      line-height: 25px;
    }
    a {
      font-family: Roboto !important;
      font-size: 1.1rem !important;
      font-weight: 200 !important;
      display: inline-flex;
      color: inherit;
      text-decoration: none;
      padding: 5px 0;
      img {
        width: 36px;
        height: 36px;
        margin-right: 20px;
      }
    }
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .mt-5 {
      margin-top: 3rem !important;
    }
    .mt-4 {
      margin-top: 1.5rem !important;
    }
    a:hover {
      color: #ffb657;
    }
    .copyright {
      margin-top: 10px;
      p {
        font-family: Roboto !important;
        font-size: 1rem !important;
        font-weight: 200 !important;
        text-transform: uppercase;
        line-height: 20px;
        margin-bottom: 5px;
      }
    }
    .rera_details {
      p:nth-child(1) {
        font-family: Roboto !important;
        font-weight: 500 !important;
        font-size: 1.2rem !important;
        margin-bottom: 0;
      }
      p:last-child {
        font-family: Roboto !important;
        font-weight: 400 !important;
        font-size: 1rem !important;
        margin-bottom: 10px;
      }
    }
    .tech_stack {
      h4 {
        display: flex;
        flex-direction: row;
        text-transform: uppercase;
        font-size: 1.5rem;
        align-items: center;
        span:last-child {
          flex-grow: 1;
          height: 1px;
          background-color: #fff;
          margin-left: 20px;
        }
      }
    }
    .tech_stack_logos {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      .icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        flex-wrap: wrap;
        .isdiv {
          flex-grow: 1;
          padding: 0 5px;

          .images {
            width: 105px !important;
            height: auto;
            @media (min-width: 800px) {
              width: 40px;
              height: auto;
            }
            @media (max-width: 1700px) {
              width: 110px;
              height: auto;
            }
          }
          .lead_img {
            max-width: 120px;
            height: auto;
            @media (max-width: 1700px) {
              width: 110px;
              height: auto;
            }
          }

          .postgres_img {
            max-width: 100px;
            height: auto;
            @media (max-width: 1700px) {
              width: 90px;
              height: auto;
            }
          }
          .vercel_img {
            max-width: 90px;
            height: auto;
            margin-left: -8px;
            @media (max-width: 1700px) {
              width: 90px;
              height: auto;
            }
          }
          .aws_img {
            max-width: 50px;
            height: auto;
            margin-top: 8px;
            margin-left: 8px;
            @media (max-width: 1700px) {
              width: 100px;
              height: auto;
            }
          }
          .cashfree_img {
            max-width: 120px;
            margin-top: 8px;
            height: auto;
            @media (max-width: 1700px) {
              width: 100px;
              height: auto;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .footer {
    .footer-container {
      padding: 0 2%;
      padding-bottom: 50px;

      .col-lg-8 {
        width: 100%;
      }
      .col-lg-3 {
        width: 100%;
      }
    }
  }

  .ro {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .tech_stack_logos {
    .icons {
      flex-wrap: wrap;
      flex: 1 1 0px;
      width: 0;
      .isdiv {
        flex: 50%;

        padding: 0 5px;
        margin-bottom: 10px;
        .images {
          width: 120px !important;
          height: auto;
          @media (max-width: 800px) {
            width: 10px;
            height: auto;
          }
        }

        .postgres_img {
          width: 100px !important;
          height: auto;
        }

        .vercel_img {
          width: 100px !important;
          height: auto;
        }

        .aws_img {
          width: 50px !important;
          margin-top: 12px;
          height: auto;
        }

        .cashfree_img {
          width: 100px !important;
          height: auto;
        }
      }
    }
  }
}


.slick-prev,
.slick-next{
  content: '' !important;
  border: none !important;
}

.slick-prev::before,
.slick-next::before {
  content: '' !important;
  border: none !important;
}