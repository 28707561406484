/* Fonts */
@mixin font($size, $weight: 400) {
    font-size: $size;
    
    font-weight: $weight;
  }
  
  @mixin font-label-s {
    @include font(18px, );
  }
  @mixin font-label-s-md {
    @include font(18px,  500);
  }
  @mixin font-label-s-bd {
    @include font(18px,  700);
  }
 
  @mixin font-label-l {
    @include font(1.389rem, );
    
  }
  @mixin font-label-l-md {
    @include font(1.389rem, 500);
   
  }
  @mixin font-label-l-bd {
    @include font(1.389rem,  700);
    
  }
  
  @mixin font-label-m {
    @include font(1.444rem);
    
  }
  @mixin font-label-m-md {
    @include font(1.444rem, 500);
   
  }
  @mixin font-label-m-bd {
    @include font(1.444rem, 700);
  
  }
  @mixin font-label-xl {
    @include font(1.667rem);
    @media (max-width: 767px) {
      @include font(15px);
    }
  }
  @mixin font-label-xl-md {
    @include font(1.667rem 500);
    @media (max-width: 767px) {
      @include font(22px, 500);
    }
  }
  @mixin font-label-xl-bd {
    @include font(1.667rem,  700);
    @media (max-width: 767px) {
      @include font(22px, 700);
    }
  }
  @mixin font-label-xxl {
    @include font(40px, 120%);
    @media (max-width: 767px) {
      @include font(1.667rem, 120%);
    }
  }
  @mixin font-label-xxl-md {
    @include font(40px,  500);
    @media (max-width: 767px) {
      @include font(30px,  500);
    }
  }
  @mixin font-label-xxl-bd {
    @include font(40px,  700);
    @media (max-width: 767px) {
      @include font(30px,  700);
    }
  }
  @mixin font-label-xxxl {
    @include font(4.444rem);
    @media (max-width: 767px) {
      @include font(2.444rem);
    }
  }
  @mixin font-label-xxxl-md {
    @include font(4.444rem,  500);
    @media (max-width: 767px) {
      @include font(2.444rem,  500);
    }
  }
  @mixin font-label-xxxl-bd {
    @include font(4.444rem,  700);
    @media (max-width: 767px) {
      @include font(2.444rem,  700);
    }
  }
  