.snackWrapper {
  position: fixed;
  z-index: 2;
  top: 90px;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
  flex-direction: row;
  justify-content: center;
  left: 50%;
  margin: 0 auto;
  display: flex;
  transform: translateX(-50%);
}

.snackContentWrapper {
  background-color: #ffb351;
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  // border: 1px solid #cacaca;
  border-radius: 5px;
  margin: 0 0.5rem;
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
}

.snackContent {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Roboto';
}
.snackButton {
  background: #1a295b;
  display: inline-flex;
  align-items: center;
  color: #fff;
  padding: 5px 1.5rem;
  border: none !important;
  outline: none !important;
  border-radius: 3px;
  font-weight: 500;
  margin-left: 15px;
}
.disabled {
  cursor: no-drop !important;
  background: #6d84cf !important;
}

.alternate {
  margin: 0px 0px 0px 0.5rem;
  color: rgb(26, 41, 91);
  font-weight: 500;
}
.btn-alternate {
  background: rgb(255, 182, 87);
  color: rgb(26, 41, 91);
  padding: 5px 10px;
}
.star-img {
  margin-right: 2px;
}
.cross-img {
  display: block;
  margin-left: 10px;
}
.d-none {
  display: none;
}
@media screen and (max-width: 768px) {
  .snackWrapper {
    display: block;
  }
  .snackContentWrapper {
    margin-bottom: 10px;
  }
  .cross-img {
    display: block;
  }
}
.dispay-none {
  display: none !important;
}
.ruppee-symbol {
  margin-left: 5px;
  font-weight: bold;
}
.ruppee-syumbol-style {
  background-color: #003cd5;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
  border-radius: 50%;
  margin-left: 10px;
}
