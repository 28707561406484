@import './variable';
@import '../../../libs//src/components/Header/components.header.module.scss';
@import '../../../libs/src/components/Footer/components.footer.module.scss';
@import '../../../libs/src/components/LoginModal/LoginModal.module.scss';
@import '../../../libs/src/components/RegistrationOtpModal/RegistrationOtpModal.module.scss';
@import '../../../libs/src/components/RegistrationModal/RegistrationModal.module.scss';
@import '../../../libs/src/components/TncModal/TncModal.module.scss';
@import '../../../libs/src/components/LoginOtpModal/LoginOtpModal.module.scss';
@import '../../../libs/src/components/CompletionModal/CompletionModal.module.scss';
@import '../../../libs/src/components/SnackBar/SnackBar.module.scss';

@media (min-width: 1700px) {
  html {
    font-family: $primary-font;
    font-weight: 100 !important;
    font-size: 18px !important;
  }
}

@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }

  .h4,
  h4 {
    font-size: 1.5rem;
  }
}

.position-relative {
  position: relative;
}

p,
ul,
figure {
  margin: 0;
}

.pt50,
.ptb50 {
  padding-top: 50px;
}

.pt80 {
  padding-top: 5rem;
}

.pb80 {
  padding-bottom: 5rem;
}

.mt80 {
  margin-top: 5rem;
}

.mb80 {
  margin-bottom: 5rem;
}

.mb50 {
  margin-bottom: 50px;
}

.mt50,
.mtb50 {
  padding-top: 50px;
}

.mt-250 {
  margin-top: -250px;

  @media (max-width: 767px) {
    margin-top: 40px;
  }
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.border-top {
  border-top: 1px solid $yellow !important;

  @media (max-width: 767px) {
    border: none !important;
  }
}

.button {
  @media (min-width: 1600px) {
    height: 60px;
  }

  background-color: $yellow;
  border-radius: 5px;
  color: $primary;
  padding: 12px 34px;
  text-transform: uppercase;
  font-family: $primary-font;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  height: 48px;
  min-width: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $primary;
  }

  @media (max-width: 991px) {
    font-size: 0.889rem;
  }

  @media (max-width: 767px) {
    min-width: 150px;
  }

  &.button-sm {
    height: 50px;
    font-size: 16px;
  }
}

.btn-white-border {
  border-color: #fff;
  color: #fff;
  padding: 5px 16px;
  font-weight: 500;
  font-size: 0.833rem;
  background-color: transparent;
  text-transform: none;
  line-height: initial;

  &:hover {
    background-color: transparent;
    border-color: #fff;
  }
}

.progress-bar {
  background-color: #6c84d5;
}

@media (min-width: 769px) {
  html {
    font-weight: 100 !important;
    font-size: 13px !important;
  }
}

@media (min-width: 1700px) {
  html {
    font-weight: 100 !important;
    font-size: 18px !important;
  }
}

@media (max-width: 768px) {
  html {
    font-weight: 100 !important;
    font-size: 13px !important;
  }
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgb(26 41 91 / 35%);
  display: flex;
  align-items: center;
  justify-content: center;
  & .loader {
    background: #1a295b;
    height: 120px;
    border-radius: 6px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// loading indicator
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.inputArea {
  .p-relative {
    position: relative;
    padding-bottom: 15px;
  }
  fieldset {
    border: none !important;
    border-bottom: 1px solid #c1c1c1 !important;
    border-radius: 0px !important;
  }
  .error_message {
    font-size: 12px;
    position: absolute;
    bottom: -5px;
    left: 15px;
    color: red;
  }
}
.timepicker {
  height: 100%;
  .timepicker__control {
    height: 100%;
    border: none !important;
    border-bottom: 1px solid #c1c1c1 !important;
    border-radius: 0px !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .timepicker__indicator-separator {
    display: none;
  }
}
.datepicker-wrap {
  .MuiInputLabel-formControl {
    transition: none !important;
    &.Mui-focused,
    &.MuiFormLabel-filled {
      opacity: 0;
    }
  }
}
.modal {
  &.modal-static {
    .modal-dialog {
      transform: scale(1) !important;
    }
  }
}
.react-tel-input .country-list {
  text-align: left;
}
.react-tel-input .country-list .search-box {
  margin: 0 0 0 6px;
}
