/* You can add global styles to this file, and also import other style files */

@import '../../../apps/landing-pages-web/styles/globals.scss';
@import '../../../apps/landing-pages-web/styles/_common.scss';

@import 'bootstrap/dist/css/bootstrap.min.css';

* {
  box-sizing: border-box;

  body {
    margin: 0;
    padding: 0;
    font-family: Roboto !important;
    overflow-x: hidden;
    max-width: 100%;
  }
}

.dashboard-nav-link-active {
  color:#FFB657;
}