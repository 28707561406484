.modalBackground {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 10000000;
}

.completionModalContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  width: 800px;
  max-height: 300px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  pointer-events: auto;
  outline: 0;
  padding: 13px;
  z-index: 1000;
}
.modalBody {
  width: 100%;
  margin: 0 auto;
}
.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
  color: red;
}
.modalBody {
  width: 100%;
  margin: 0 auto;
}
.title {
  h3 {
    padding-top: 13px;
    margin: 0;
    margin-bottom: 6.5px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: 0;
    color: #000;
    opacity: 1;
    @media(max-width:767px){
      font-size: 20px;
      padding-bottom:0 ;
    }
  }
}
.congoWrapper {
  margin: 1.5rem 5rem;
  display: flex;
  justify-content: center;
}
.gridCongoWrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 100%;
}

.grid-items-1 {
  height: 70px;
  width: 70px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 60%;
}
.grid-items-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  p {
    margin-bottom: 0;
    word-wrap: break-word;
    text-align: left;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    color: #000;
  }
  span {
    font-weight: 700;
    font-size: 15px;
  }
}
@media screen and (max-width: 900px) {
  .completionModalContainer {
    max-width: 500px;
    max-height: 600px;
  }
  .grid-items-1 {
    padding-left: 0%;
    padding-right: 20%;
  }
}
@media screen and (max-width: 560px) {
  .completionModalContainer {
    max-width: 400px;
    max-height: 600px;
  }
  .congoWrapper {
    margin: 1rem 3rem;
  }
  .gridCongoWrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    .grid-items-1 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 50%;
      margin-bottom: 8px;
    }
    .grid-items-2{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.free-text{
  color:black;
  font-weight: bold;
}
