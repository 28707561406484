@import './common';
@import './mixine';
@import './variable';
@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  padding: 0;
  margin: 0;
  font-family: $primary-font;
  color: $primary;
  font-size: 1rem;
  line-height: 1.5;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 30px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.list-unstyled {
  list-style: none;
}

hr:not([size]) {
  height: 1px;
}

/*****slick slider******/
.slick-prev {
  left: 20px;
  z-index: 1;
  top: 250px;

  @media (max-width: 767px) {
    top: 20%;
  }

  &::before {
    opacity: 1;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-right-style: solid;
    border-right-width: 2px;
    content: '';
    display: inline-block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
    transform: rotate(136deg);
  }
}
.slick-current > div {
  height: 100%;
}
.slick-next {
  right: 20px;
  top: 250px;

  @media (max-width: 767px) {
    top: 20%;
  }

  &::before {
    opacity: 1;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-right-style: solid;
    border-right-width: 2px;
    content: '';
    display: inline-block;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
    transform: rotate(-45deg);
  }
}

.slick-dots {
  bottom: 0;
  left: 0;
  right: 0;

  li {
    width: 68px;
    height: 3px;
    margin: 0 7px;

    @media (max-width: 767px) {
      width: 40px;
      margin: 0 7px;
    }

    button {
      width: 100%;
      height: auto;

      &::before {
        width: 100%;
        height: 100%;
        content: '';
        color: $yellow;
        background-color: #b0b1b5;
        opacity: 1;
      }
    }

    &.slick-active {
      button {
        &:before {
          opacity: 1;
          color: $yellow;
          background-color: $yellow;
        }
      }
    }
  }
}

.hero_slider {
  .slick-prev,
  .slick-next {
    top: 48%;
  }
  .slick-next {
    right: 3%;
  }

  .slick-prev {
    left: 3%;
  }

  .slick-dots {
    bottom: 100px;
    @media (max-width: 1280px) {
      bottom: 14%;
    }
    li {
      button {
        &:before {
          background-color: #ffffff;
        }
      }

      &.slick-active {
        button {
          &:before {
            background-color: #ffb657;
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .slick-next {
      right: 15px;
    }

    .slick-prev {
      left: 15px;
    }
  }

  @media (max-width: 767px) {
    .slick-next,
    .slick-prev {
      display: none !important;
    }

    .slick-dots {
      bottom: 60px;
    }
  }
}

.property_main_inner {
  .slick-next,
  .slick-prev {
    top: 23%;
  }
}

.experts_videos_slider {
  .slick-dots {
    bottom: 0px;

    li {
      button {
        &:before {
          background-color: #b0b1b5;
        }
      }

      &.slick-active {
        button {
          &:before {
            background-color: #ffb657;
          }
        }
      }
    }
  }
}

.tenants_slider {
  .slick-dots {
    bottom: -60px;
  }
}

.form-select:focus {
  border-color: rgba(0, 0, 0, 0.125);
  outline: 0;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
}

/****accourdion******/
.accordion-header {
  position: relative;

  .accordion-button {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    color: $primary;
    background-color: rgba(0, 0, 0, 0.03);
    padding: 13px 40px 13px 16px;

    &::after {
      display: none;
    }

    @media (max-width: 767px) {
      background-color: #f9f9f9;
      border: none;
      box-shadow: none;
      font-size: 15px;
    }
  }

  button[aria-expanded='true'] {
    div {
      transform: rotate(180deg);
      top: 35%;
    }
  }
}

html .btn:focus,
html .btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgb(108 117 125 / 50%);
}

.btn:active,
.btn:focus {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.link_view_wrap {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-top: 1rem;
  display: none;

  @media (max-width: 767px) {
    display: block;
    margin-top: 1rem;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1500px;
  }
}

@media (max-width: 1024px) {
  html,
  body {
    font-weight: 100 !important;
    font-size: 13px !important;
  }
}

.active-tabs span {
  background-color: #ffb657 !important;
}

.selected {
  font-weight: 700 !important;
  color: #fff !important;
}

.scroll_top_btn {
  // display: none;
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 8;
  border: none;
  outline: none;
  background-color: rgb(26, 41, 91);
  cursor: pointer;
  padding: 12px;
  border-radius: 4px;
  font-size: 0;
  width: 48px;
  height: 51px;
}

.scroll_top_btn:before {
  content: '';
  height: 16px;
  position: absolute;
  right: 0;
  transform-origin: bottom;
  width: 2px;
  z-index: 1;
  background: rgb(255, 182, 87);
  top: 18px;
  bottom: auto;
  left: 0;
  margin: 0 auto;
}

.scroll_top_btn:after {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
  content: '';
  display: inline-block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 12px;
  border-color: rgb(255, 182, 87);
  transform: rotate(-136deg);
  right: 0;
  margin: 0 auto;
}

.serverErrorMessage {
  color: red;
  display: flex;
  justify-content: center;
}

.callback_btn {
  position: fixed;
  bottom: 0;
  right: 19px;
  background: #1a295b;
  border-radius: 5px 5px 0 0;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.54px;
  color: #fff;
  border: none;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  display: inline-flex;
  align-items: center;

  span {
    margin-right: 7px !important;
  }

  @media (max-width: 767px) {
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 180px;
    padding: 10px 10px;
  }
}

@media (max-width: 480px) {
  .tenants_slider {
    .slick-track {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100% !important;

      .slick-slide {
        display: block;
        width: 50% !important;
        text-align: center;
      }
    }
  }
}

@media (max-width: 991px) {
  .careers_whyUs {
    .modal.show .modal-dialog {
      max-width: 90%;
      margin: 0 auto;
    }
  }
}
@media (min-height: 550px) and (max-height: 768px) {
  .MuiPickersPopper-root {
    width: 320px;
    left: 25px !important;

    .css-169iwlq-MuiCalendarPicker-root {
      width: 250px !important;
      overflow: hidden;
      max-height: 260px;
      .MuiTypography-root.MuiTypography-caption {
        width: 26px !important;
        height: 30px !important;
      }
      .MuiButtonBase-root,
      .MuiPickersDay-root {
        width: 26px !important;
        height: 26px !important;
      }
    }
    .MuiPickersCalendarHeader-root {
      margin-top: 10px !important;
    }
    .MuiYearPicker-root {
      max-height: 215px !important;
    }
  }
}
#property-dropdown {
  position: relative;
  padding-right: 0px;
  &:hover {
    color: #1a295b;
  }
  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    right: 10px;
    position: absolute;
    border-bottom: 0;
    border-left: 0;
    display: block;
    margin: 0;
    transform: rotate(135deg);
    top: 5px;
    transition: all 0.3s;
  }
}

.MuiPickersCalendarHeader-label {
  font-size: 14px;
}

.dropdown-menu {
  width: 100%;
  background: #f3f3f3;
  max-height: 240px;
  z-index: 1;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(119, 119, 119, 0.2);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 8px;
    min-height: 30px;
    max-height: 30px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(99, 99, 99, 0.1);
    background-color: #90a4ae;
  }

  @media (max-width: 767px) {
    left: -20px !important;
    padding: 0;
    border-radius: 5px;
    margin-top: 8px;
  }
}
.show.dropdown {
  a {
    &:active {
      background-color: transparent;
    }
    &:hover {
      background-color: transparent;
    }
  }
  #property-dropdown {
    &:after {
      transform: rotate(-45deg);
      top: 10px;
    }
  }
}

.funded_text {
  background-color: rgba(26, 41, 91, 1) !important;
}

.jira_issue_btn {
  position: fixed;
  bottom: 0;
  left: 19px;
  background: $gray;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.54px;
  color: #fff;
  border: none;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  display: inline-flex;
  align-items: center;
}
.btn-btn-link {
  border-style: none;
}


@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}